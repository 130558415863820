// 消息管理相关接口
import request from '@/request/request'

// 消息列表
export const GetNoticeMessageList = (params) => request.post('adminv2/getNoticeMessageList', params);

// 添加消息
export const AddNoticeMessage = (params) => request.post(`/adminv2/addNoticeMessage`, params );

// 编辑消息
export const EditNoticeMessage = (params) => request.post('/adminv2/editNoticeMessage', params);

// 消息详情
export const GetNoticeMessage = (params) => request.post('/adminv2/getNoticeMessage', params);

// 删除推送
export const DelNoticeMessage = (params) => request.post('/adminv2/delNoticeMessage', params);

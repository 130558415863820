<template>
    <div class="ant-card" id="course_category">
        <a-spin v-if="$route.meta.isShow" :spinning="spinning" size="large">
            <div class="ant-card-body">
                <div class="head-box">
                    <div class="table-operator">
                        <a-select v-model="form.channel" style="width: 210px" class="margin_right20" placeholder="请选择推送渠道">
                            <a-select-option :value="0">全部</a-select-option>
                            <a-select-option :value="1">APP私信</a-select-option>
                            <a-select-option :value="2">APP私信+PUSH</a-select-option>
                        </a-select>
                        <a-select v-model="form.pushStatus" style="width: 210px" class="margin_right20" placeholder="请选择推送状态">
                            <a-select-option :value="0">全部</a-select-option>
                            <a-select-option :value="1">待发送</a-select-option>
                            <a-select-option :value="2">已发送</a-select-option>
                        </a-select>
                        <a-range-picker @change="onChangeDate" style="width: 210px;" class="margin_right20"/>
                        <a-input v-model="form.title" placeholder="请输入消息名称" style="width: 210px;" class="margin_right20"/>
                        <a-button type="primary" @click="searchClick" class="margin_right20">搜索</a-button>
                        <a-button icon="plus" type="primary" @click="onAdd" v-show="isShowAdd">新建推送</a-button>
                    </div>
                </div>
                <div class="contant-box">
                    <a-table ref="table" :columns="columns" :data-source="tableList" :row-key="record => record.id" :loading="tableLoading" :pagination="false">
                        <template slot="channel" slot-scope="channel, record">
                            <span>{{record.channel == 1 ? 'APP私信' : 'APP私信+PUSH'}}</span>
                        </template>
                        <span slot="pushStatus" slot-scope="pushStatus, record">
                            <a-badge v-show="record.pushStatus == 1" status="processing" text="待发送"/>
                            <a-badge v-show="record.pushStatus == 2" status="success" text="已发送"/>
                        </span>
                        <span slot="action" slot-scope="text, record">
                            <template>
                                <a-popconfirm placement="bottom" title="确定发送此条推送吗？" ok-text="确定" cancel-text="取消" @confirm="onDel(record)">
                                    <a v-show="isShowDel" class="margin_right20">删除</a>
                                </a-popconfirm>
                                <a v-show="isShowDet" class="margin_right20" @click="onEdit(record)">查看</a>
                            </template>
                        </span>
                    </a-table>
                </div>
                <!-- 分页功能 -->
                <MyPagination :count="count" :pageNo="pageNo" @showSizeChangeFn="showSizeChangeFn" v-if="!tableLoading"/>
            </div>
        </a-spin>
        <router-view/>
    </div>
</template>

<script>
import MyPagination from "@/components/pagination/MyPagination";
import {codeFn} from "@/utils/tools";
import {DelNoticeMessage, GetNoticeMessageList} from "@/request/api/noticeManage";
export default {
    components: {MyPagination},
    created() {
        if (codeFn("/adminv2/addNoticeMessage")) this.isShowAdd = true; //新增
        if (codeFn("/adminv2/getNoticeMessage")) this.isShowDet = true; //详情
        if (codeFn("/adminv2/delNoticeMessage")) this.isShowDel = true; //删除
    },
    data() {
        return {
            spinning: false, // 全局loading
            form:{
                channel:undefined,
                pushStatus:undefined,
                title:'',
                startTime:'',
                endTime:'',
            },
            columns: [
                {
                    title: "推送渠道",
                    dataIndex: "channel",
                    key: "channel",
                    scopedSlots: {customRender: "channel"},
                },
                {
                    title: "名称",
                    dataIndex: "title",
                    key: "title",
                },
                {
                    title: "发送状态",
                    dataIndex: "pushStatus",
                    key: "pushStatus",
                    scopedSlots: {customRender: "pushStatus"},
                },
                {
                    title: "发送条数",
                    dataIndex: "count",
                    key: "count",
                },
                {
                    title: "已读条数",
                    dataIndex: "readCount",
                    key: "readCount",
                },
                {
                    title: "创建时间",
                    dataIndex: "createTime",
                    key: "createTime",
                },
                {
                    title: "操作",
                    dataIndex: "action",
                    key: "action",
                    width: "160px",
                    scopedSlots: {customRender: "action"},
                },
            ],
            tableList: [],
            tableLoading: false,
            confirmLoading: false,
            isShowAdd: false, // 新增是否隐藏
            isShowDet: false, // 详情是否隐藏
            isShowDel: false, // 删除是否隐藏
            pageNo: 1, // 当前页
            pageSize: 20, // 每页条数
            count: 0// 列表的总条数
        };
    },
    mounted() {
        this.getTagList();
    },
    
    watch: {
        $route(to) {
            if (to.path === "/noticeManage/noticeIndex") {
                this.getTagList();
            }
        },
    },
    
    methods: {
        // 搜索按钮
        searchClick(){
            this.pageNo = 1
            this.getTagList()
        },
        
        // 搜索 - 修改时间
        onChangeDate(date, dateString) {
            if (dateString[0] != "") {
                this.form.startTime = dateString[0]+' 00:00:00';
                this.form.endTime = dateString[1]+' 23:59:59';
            } else {
                this.form.startTime = "";
                this.form.endTime = "";
            }
        },
        // 分页功能切换的回调
        showSizeChangeFn(current, pageSize) {
            this.pageNo = current;
            this.pageSize = pageSize;
            this.getTagList();
        },
        // 列表数据
        getTagList() {
            this.tableLoading = true;
            GetNoticeMessageList({
                title:this.form.title,
                pushStatus:this.form.pushStatus == undefined ? 0 : this.form.pushStatus,
                channel:this.form.channel == undefined ? 0 : this.form.channel,
                startTime:this.form.startTime,
                endTime:this.form.endTime,
                pageNo: this.pageNo,
                pageSize: this.pageSize
            }).then((res) => {
                if (res.code === 200) {
                    this.tableList = res.data.data;
                    this.count = res.data.count;
                }
                this.tableLoading = false;
            });
        },

        onAdd() {
            this.$router.push("/noticeManage/NoticeEdit/0");
        },
        onEdit(record) {
            this.$router.push(`/noticeManage/NoticeEdit/${record.id}`);
        },
        
        // 删除推送
        onDel(record) {
            DelNoticeMessage({id: record.id}).then(({code, msg}) => {
                if (code === 200) {
                    this.$message.success('删除成功')
                    this.getTagList();
                } else {
                    message.error(msg || "操作失败，请稍后重试");
                }
            })
        },
        // 回到顶部的函数
        targetFn() {
            return document.querySelector("#course");
        },
    }
};
</script>

<style lang="less" scoped>
#course_category {
    height: 100%;
    overflow: hidden;
    overflow-y: scroll;
}

.head-box {
    margin-bottom: 30px;
}

.avatar {
    width: 160px;
    height: 80px;
    object-fit: contain;
}

.btn-edit {
    margin-right: 40px;
}
</style>
